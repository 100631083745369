// domainActions.js
export const handleAddDomain = (state, action) => {
    console.log('action.payload: ',action.payload)
    const newDomain = {
      id: (state.domains.length + 1).toString(),
      ...action.payload,
    };
    state.domains.push(newDomain);
  };
  
  export const handleEditDomain = (state, action) => {
    const { _id, ...updatedDomain } = action.payload;
    const domain = state.domains.find((domain) => domain._id === _id);
    if (domain) {
      Object.assign(domain, updatedDomain);
    }
  };
  
  export const handleRemoveDomain = (state, action) => {
    state.domains = state.domains.filter((domain) => domain._id !== action.payload);
  };
  