// ModalBox.jsx
import React from 'react';
import './ModalBox.css'; // If you have specific styles for the modal

const ModalBox = ({ isOpen, onClose, onSave, taskTitle, onTitleChange, isEditing, onRemoveTask, onRemoveTaskWithChildren }) => {
  if (!isOpen) return null;

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSave();
    }
  };
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-popup">
        <h2>{isEditing ? 'Edit Task' : 'Add New Task'}</h2>
        <input
          type="text"
          placeholder="Task Title"
          value={taskTitle}
          onChange={onTitleChange}
          onKeyPress={handleKeyPress} // Add the key press handler here
        />
        <div className='main-button_task'>
          <button onClick={onSave}>{isEditing ? 'Edit' : 'Save'}</button>
          <button className='cancel_button' onClick={onClose}>Cancel</button>
        </div>
        {isEditing && (
          <div className='remove-button_task'>
            <button onClick={onRemoveTask}>Remove Task</button>
            <button onClick={onRemoveTaskWithChildren}>Remove Task & Children</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalBox;