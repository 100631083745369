// domainApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://todo-api.gulnuru.us/api', // Replace with your API's base URL
  prepareHeaders: (headers) => {
    // Add any necessary headers here
    return headers;
  },
  responseHandler: async (response) => {
    const text = await response.text();
    if (!text) {
      return {};
    }
    try {
      return JSON.parse(text);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      throw new Error('Failed to parse server response');
    }
  },
});

export const domainApi = createApi({
  reducerPath: 'domainApi',
  baseQuery: baseQuery,
  tagTypes: ['Domain'],
  endpoints: (builder) => ({
    fetchDomains: builder.query({
      query: () => '/domains',
      providesTags: ['Domain'],
    }),
    fetchDomainsByOwnerId: builder.query({
        query: (ownerId) => `domains?ownerId=${ownerId}`,
        providesTags: ['Domain'],
    }),
    addDomain: builder.mutation({
      query: (newDomain) => ({
        url: '/domains',
        method: 'POST',
        body: newDomain,
      }),
      invalidatesTags: ['Domain'],
    }),
    editDomain: builder.mutation({
      query: ({ _id, ...updatedDomain }) => ({
        url: `/domains/${_id}`,
        method: 'PUT',
        body: updatedDomain,
      }),
      invalidatesTags: ['Domain'],
    }),
    removeDomain: builder.mutation({
      query: (_id) => ({
        url: `/domains/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Domain'],
    }),
  }),
});

export const {
  useFetchDomainsQuery,
  useFetchDomainsByOwnerIdQuery,
  useAddDomainMutation,
  useEditDomainMutation,
  useRemoveDomainMutation,
} = domainApi;
