// DomainList.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchDomainsByOwnerIdQuery, useAddDomainMutation, useEditDomainMutation, useRemoveDomainMutation } from '../../redux/domain/domainApi';
import DomainModalBox from './DomainModalBox';
import { selectDomain } from '../../redux/domain/domainSlice';
import { Link } from 'react-router-dom';

const DomainList = () => {
  // const { user } = useSelector((state) => state.auth); // Get user from Redux state
  // const dispatch = useDispatch();
  // const _ownerId = '66cda43587bec3ac4e3de664';
  // const { data: domains = [], isLoading: loadingDomains, isError, error } = useFetchDomainsByOwnerIdQuery(_ownerId);
  
  const userId = useSelector((state) => state.auth.user?.id);
  const dispatch = useDispatch();
  const { data: domains = [], isLoading: loadingDomains, isError, error } = useFetchDomainsByOwnerIdQuery(userId);
  const selectedDomainId = useSelector((state) => state.domains?.selectedDomainId);

  const [addDomain] = useAddDomainMutation();
  const [editDomain] = useEditDomainMutation();
  const [removeDomain] = useRemoveDomainMutation();

  const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
  const [newDomainTitle, setNewDomainTitle] = useState('');
  const [editingDomainId, setEditingDomainId] = useState(null);

  useEffect(() => {
    if (domains.length > 0 && !selectedDomainId) {
      dispatch(selectDomain(domains[0]._id));
    }
  }, [domains, selectedDomainId, dispatch]);

  const handleAddDomain = () => {
    setNewDomainTitle('');
    setEditingDomainId(null);
    setIsDomainModalOpen(true);
  };

  const handleEditDomain= (domainId, domainTitle) => {
    setNewDomainTitle(domainTitle || '');
    setEditingDomainId(domainId);
    setIsDomainModalOpen(true);
  };

  const handleModalClose = () => {
    setNewDomainTitle('');
    setIsDomainModalOpen(false);
    setEditingDomainId(null);
  };

  const handleSaveDomain= async () => {
    if (newDomainTitle.trim()) {
      if (editingDomainId) {
        await editDomain({ _id: editingDomainId, title: newDomainTitle });
      } else {
        await addDomain({ title: newDomainTitle, ownerId: userId });
      }
      handleModalClose();
    }
  };

  const handleRemoveDomain = async () => {
    if (editingDomainId) {
      await removeDomain(editingDomainId);
      handleModalClose();
    }
  };

  if (loadingDomains) return <div>Loading...</div>;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>Domain List</h1>
        <button onClick={handleAddDomain}>
          <span className="icon-add"></span> Add Domains
        </button>
      </div>
      <div className='list-container'>
        <ul className='ul-domains-list'>
          {domains.map(domain=> (
            <li key={domain._id} className='domains-list list-item' >
              <div className='domain-title'>{domain.title}</div>
              <div className='domain-operations'>
                  <button className='domain-operations-edit edit-button' onClick={() => handleEditDomain(domain._id, domain.title)}>
                    <span className={`icon-edit`} />
                  </button>
                  <Link to={'/domains/' + domain._id + '/projects'} className={'ml10px domain-operations-tasks doc-list-button'}>
                    <span className={`icon-doc-list`} />
                  </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <DomainModalBox
        isOpen={isDomainModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveDomain}
        domainTitle={newDomainTitle}
        onTitleChange={(e) => setNewDomainTitle(e.target.value)}
        isEditing={!!editingDomainId}
        onRemoveDomain={handleRemoveDomain}
      />
    </>
  );
};

export default DomainList;


