// Task.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { useArchiveTaskMutation, useUnarchiveTaskMutation, usePinTaskMutation, useUnpinTaskMutation, useReorderTaskUpMutation, useReorderTaskDownMutation } from '../../redux/task/taskApi';
import TaskCheckbox from './TaskCheckbox';
import TaskTitle from './TaskTitle';
import TaskButton from './TaskButton';

export default function TaskListItem({ task: { _id, title, state, parentId }, onAddTask, onEditTask }) {
  const [archiveTask] = useArchiveTaskMutation();
  const [unarchiveTask] = useUnarchiveTaskMutation();
  const [pinTask] = usePinTaskMutation();
  const [unpinTask] = useUnpinTaskMutation();
  const [reorderTaskUp] = useReorderTaskUpMutation();
  const [reorderTaskDown] = useReorderTaskDownMutation();

  const handleArchiveCheckboxChange = async () => {
    if (state === 'TASK_ARCHIVED') {
      await unarchiveTask(_id);
    } else {
      await archiveTask(_id);
    }
  };

  const handlePinButtonClick = async () => {
    if (state === 'TASK_PINNED') {
      await unpinTask(_id);
    } else {
      await pinTask(_id);
    }
  };

  const handleAddButtonClick = () => {
    onAddTask(_id);
  };

  const handleEditButtonClick = () => {
    onEditTask(_id, title);
  };

  const handleReorderTaskUpButtonClick = async () => {
    await reorderTaskUp({ _id, parentId });
  };

  const handleReorderTaskDownButtonClick = async () => {
    await reorderTaskDown({ _id, parentId });
  };
  
  return (
    <div key={_id} className={`list-item ${state}`}>
      <TaskCheckbox id={_id} checked={state === 'TASK_ARCHIVED'} onChange={handleArchiveCheckboxChange} />
      <TaskTitle id={_id} title={title} />
      
      {state !== 'TASK_ARCHIVED' && (
        <TaskButton id={_id} onClick={handlePinButtonClick} ariaLabel={`pinTask-${_id}`} iconClass="star" />
      )}
      <TaskButton id={_id} onClick={handleReorderTaskUpButtonClick} ariaLabel={`up-${_id}`} iconClass="up" />
      <TaskButton id={_id} onClick={handleReorderTaskDownButtonClick} ariaLabel={`down-${_id}`} iconClass="down" />
      <TaskButton id={_id} onClick={handleEditButtonClick} ariaLabel={`edit-${_id}`} iconClass="edit" />
      <TaskButton id={_id} onClick={handleAddButtonClick} ariaLabel={`add-${_id}`} iconClass="add" />
    </div>
  );
}

TaskListItem.propTypes = {
  task: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired,
  }).isRequired,
  onAddTask: PropTypes.func.isRequired,
  onEditTask: PropTypes.func.isRequired,
};
