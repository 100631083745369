// components/projects/ProjectModalBox.jsx
import React from 'react';
import '../task/ModalBox.css'

const ProjectModalBox = ({
  isOpen,
  onClose,
  onSave,
  projectTitle,
  onTitleChange,
  isEditing,
  onRemoveProject
}) => {
  if (!isOpen) return null;
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSave();
    }
  };
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  return (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-popup">
          <h2>{isEditing ? 'Edit Project' : 'Add Project'}</h2>
          <input
            type="text"
            placeholder="Project Title"
            value={projectTitle}
            onChange={onTitleChange}
            onKeyPress={handleKeyPress} // Add the key press handler here
          />
          <div className='main-button_project'>
            <button onClick={onSave}>{isEditing ? 'Save Changes' : 'Add Project'}</button>
            <button className='cancel_button' onClick={onClose}>Cancel</button>
          </div>
          {isEditing && (
            <div className='remove-button_project'>
              <button onClick={onRemoveProject}>Remove Project</button>
            </div>
          )}
        </div>
      </div>
  );
};

export default ProjectModalBox;
