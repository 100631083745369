export const buildHierarchy = (tasks) => {
  const taskMap = {};
  const roots = [];

  // Log initial tasks
  console.log('Initial tasks:', tasks);

  // Map tasks by their ID
  tasks.forEach(task => {
    taskMap[task._id] = { ...task, children: [] };
  });

  // Log task map
  console.log('TaskMap:', taskMap);

  // Build the hierarchy
  tasks.forEach(task => {
    if (task.parentId === '0') {
      // This is a root-level task
      roots.push(taskMap[task._id]);
    } else if (taskMap[task.parentId]) {
      // This task has a parent, so add it as a child
      taskMap[task.parentId].children.push(taskMap[task._id]);
    }
  });

  // Log the root tasks before sorting
  console.log('Root tasks before sorting:', roots);

  // Function to determine if any child task is pinned
  const isAnyChildPinned = (task) => {
    if (task.state === 'TASK_PINNED') {
      return true;
    }
    return task.children.some(isAnyChildPinned);
  };

  // Sort tasks based on your criteria
  const sortTasks = (taskList) => {
    return taskList.sort((a, b) => {
      const stateOrder = {
        TASK_PINNED: 1,
        TASK_INBOX: 2,
        TASK_ARCHIVED: 3,
      };

      const aPinned = isAnyChildPinned(a) ? 1 : 0;
      const bPinned = isAnyChildPinned(b) ? 1 : 0;

      if (aPinned !== bPinned) {
        return bPinned - aPinned; // Move tasks with pinned children to the top
      }

      if (stateOrder[a.state] !== stateOrder[b.state]) {
        return stateOrder[a.state] - stateOrder[b.state];
      }

      return a.order - b.order;
    });
  };

  const sortHierarchy = (tasks) => {
    tasks = sortTasks(tasks);
    tasks.forEach(task => {
      task.children = sortTasks(task.children);
      sortHierarchy(task.children);
    });
  };

  sortHierarchy(roots);

  // Log the final roots after sorting
  console.log('Roots after sorting:', roots);

  return roots;
};
