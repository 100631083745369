// TaskCheckbox.jsx
import React from 'react';
import PropTypes from 'prop-types';

const TaskCheckbox = ({ id, checked, onChange }) => (
  <label
    htmlFor={`archiveTask-${id}`}
    aria-label={`archiveTask-${id}`}
    className="checkbox checkbox-label"
  >
    <input
      type="checkbox"
      name="checked"
      id={`archiveTask-${id}`}
      checked={checked}
      onChange={onChange}
    />
    <span className="checkbox-custom" />
  </label>
);

TaskCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TaskCheckbox;
