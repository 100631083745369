// store.js
import { configureStore } from '@reduxjs/toolkit';
import taskReducer from './task/taskSlice';
import projectReducer from './project/projectSlice';
import { taskApi } from './task/taskApi';
import { projectApi } from './project/projectApi';
import { authApi } from './auth/authApi';
import authReducer from './auth/authSlice';
import { domainApi } from './domain/domainApi';
import domainReducer from './domain/domainSlice';
// import { reducer as domainReducer } from './domain/domainSlice';


export const store = configureStore({
  reducer: {
    tasks: taskReducer,
    projects: projectReducer,
    auth: authReducer, // Add auth slice
    [taskApi.reducerPath]: taskApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    domain: domainReducer, // Add domain slice
    [domainApi.reducerPath]: domainApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(taskApi.middleware, projectApi.middleware, authApi.middleware,domainApi.middleware),
});





