// domainSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { handleAddDomain, handleEditDomain, handleRemoveDomain } from '../domain/domainActions';

const initialState = {
  domains: [],
  selectedDomainId: null,
};

const domainSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    addDomain: handleAddDomain,
    editDomain: handleEditDomain,
    removeDomain: handleRemoveDomain,
    selectDomain: (state, action) => {
      state.selectedDomainId = action.payload;
    },
  },
});

export const {
  addDomain,
  editDomain,
  removeDomain,
  selectDomain,
} = domainSlice.actions;

export default domainSlice.reducer;
