// TaskTitle.jsx
import React from 'react';
import PropTypes from 'prop-types';

const TaskTitle = ({ id, title }) => (
  <label htmlFor={`title-${id}`} aria-label={title} className="title">
    <input
      type="text"
      value={title}
      name="title"
      id={`title-${id}`}
      placeholder="Input title"
      readOnly={true}
    />
  </label>
);

TaskTitle.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TaskTitle;
