// components/domains/DomainModalBox.jsx
import React from 'react';
import '../task/ModalBox.css'
const DomainModalBox = ({
  isOpen,
  onClose,
  onSave,
  domainTitle,
  onTitleChange,
  isEditing,
  onRemoveDomain
}) => {
  if (!isOpen) return null;
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSave();
    }
  };
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  return (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-popup">
          <h2>{isEditing ? 'Edit Domain' : 'Add Domain'}</h2>
          <input
            type="text"
            placeholder="Domain Title"
            value={domainTitle}
            onChange={onTitleChange}
            onKeyPress={handleKeyPress} // Add the key press handler here
          />
          <div className='main-button_domain'>
            <button onClick={onSave}>{isEditing ? 'Save Changes' : 'Add Domain'}</button>
            <button className='cancel_button' onClick={onClose}>Cancel</button>
          </div>
          {isEditing && (
            <div className='remove-button_domain'>
              <button onClick={onRemoveDomain}>Remove Domain </button>
            </div>
          )}
        </div>
      </div>
  );
};
export default DomainModalBox;
