// authApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { loginSuccess } from './authSlice';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://todo-api.gulnuru.us/api', // Replace with your base API URL
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken'); // Use sessionStorage for token
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    signin: builder.mutation({
      query: (credentials) => ({
        url: '/auth/signin',
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Save user data and token to session storage
          sessionStorage.setItem('user', JSON.stringify(data));
          sessionStorage.setItem('accessToken', data.accessToken);
          console.log("sessionStorage.getItem('user')", sessionStorage.getItem('user'))
          // Dispatch loginSuccess action to update Redux store
          dispatch(loginSuccess(data));
        } catch (error) {
          console.error('Signin failed: ', error);
        }
      },
    }),
    signup: builder.mutation({
      query: (newUser) => ({
        url: '/auth/signup',
        method: 'POST',
        body: newUser,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Save user data and token to session storage
          // sessionStorage.setItem('user', JSON.stringify(data));
          // sessionStorage.setItem('accessToken', data.accessToken);
          // Dispatch loginSuccess action to update Redux store
          console.log('loginSuccess in onQueryStarted',data);
          dispatch(loginSuccess(data));
        } catch (error) {
          console.error('Signup failed: ', error);
        }
      },
    }),
  }),
});

export const { useSigninMutation, useSignupMutation } = authApi;
