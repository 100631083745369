// TaskButton.jsx
import React from 'react';
import PropTypes from 'prop-types';

const TaskButton = ({ id, onClick, ariaLabel, iconClass }) => (
  <button
    className={`${iconClass}-button`}
    onClick={onClick}
    id={`${iconClass}-${id}`}
    aria-label={ariaLabel}
    key={`${iconClass}-${id}`}
  >
    <span className={`icon-${iconClass}`} />
  </button>
);

TaskButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
};

export default TaskButton;


