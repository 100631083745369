// TaskList.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useFetchTasksByProjectIdQuery, useAddTaskMutation, useEditTaskMutation, useRemoveTaskMutation, useRemoveTaskWithChildrenMutation } from '../../redux/task/taskApi';
import ModalBox from '../task/ModalBox';
import { buildHierarchy } from '../../utils/taskUtils';
import TaskListItem from './TaskListItem';

const TaskList = () => {
  const { projectId } = useParams();  // Get projectId from URL params
  const { data: tasks = [], isLoading, isError, error } = useFetchTasksByProjectIdQuery(projectId);
  const [addTask] = useAddTaskMutation();
  const [editTask] = useEditTaskMutation();
  const [removeTask] = useRemoveTaskMutation();
  const [removeTaskWithChildren] = useRemoveTaskWithChildrenMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [parentId, setParentId] = useState(null);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [sortedTasks, setSortedTasks] = useState([]);
  const [isTaskEdited, setIsTaskEdited] = useState(false);

  useEffect(() => {
    const sorted = buildHierarchy(tasks);
  
    // Only update if sorted tasks are actually different
    if (JSON.stringify(sorted) !== JSON.stringify(sortedTasks)) {
      setSortedTasks(sorted);
    }
  
    if (isTaskEdited) {
      setIsTaskEdited(false);
    }
  }, [tasks, isTaskEdited, sortedTasks]); // Add sortedTasks as a dependency
  
  const handleAddTask = (parentId) => {
    setParentId(parentId);
    setNewTaskTitle('');
    setEditingTaskId(null);
    setIsModalOpen(true);
  };

  const handleEditTask = (taskId, taskTitle) => {
    setNewTaskTitle(taskTitle || '');
    setEditingTaskId(taskId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setNewTaskTitle('');
    setIsModalOpen(false);
    setParentId(null);
    setEditingTaskId(null);
  };

  const handleSaveTask = async () => {
    if (newTaskTitle.trim()) {
      if (editingTaskId) {
        await editTask({ _id: editingTaskId, title: newTaskTitle });
        setIsTaskEdited(true);
      } else {
        await addTask({ title: newTaskTitle, parentId: parentId || '0', projectId });
      }
      handleModalClose();
    }
  };

  const handleRemoveTask = async () => {
    if (editingTaskId) {
      await removeTask(editingTaskId);
      handleModalClose();
    }
  };

  const handleRemoveTaskWithChildren = async () => {
    if (editingTaskId) {
      await removeTaskWithChildren(editingTaskId);
      handleModalClose();
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) {
    if (error.status) {
      if (error.status === 404) {
        return <div>No tasks found for this project.</div>;
      } else if (error.status === 500) {
        return <div>Server error. Please try again later.</div>;
      } else {
        return <div>An error occurred: {error.error}</div>;
      }
    } else {
      return <div>An error occurred: {error.message}</div>;
    }
  }

  const renderTasks = (tasks) => {
    if (tasks.length === 0) {
      return (
        <div style={{ margin: '20px' }}>
          No tasks yet.
        </div>
      );
    }

    return tasks.map((task) => (
      <div key={task._id} className={task.parentId === '0' ? 'ml0' : 'ml20'}>
        <TaskListItem
            key={task._id}
            task={task}
            onAddTask={handleAddTask}
            onEditTask={handleEditTask}
          />
        {task.children.length > 0 && renderTasks(task.children)}
      </div>
    ));
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1>Task List</h1>
        <div>
          <Link to={'/projects'} className={'mr20px underline-none'}>
            Projects
          </Link>
          <button
            className="add-button button-without-width"
            onClick={() => handleAddTask('0')}
            aria-label="addTaskParent"
          >
            <span className="icon-add"></span> Add Task
          </button>
        </div>
      </div>
      <div className="list-container">
        {renderTasks(sortedTasks)}
        </div>
      <ModalBox
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSave={handleSaveTask}
        taskTitle={newTaskTitle}
        onTitleChange={(e) => setNewTaskTitle(e.target.value)}
        isEditing={!!editingTaskId}
        onRemoveTask={handleRemoveTask}
        onRemoveTaskWithChildren={handleRemoveTaskWithChildren}
      />
    </>
  );
};

export default TaskList;
